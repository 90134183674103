import { Pagination } from 'react-bootstrap'

const PaginationComponent = ({ meta, currentPage, setCurrentPage }) => {
  return (
    <>
      {meta?.Total > 0 && (
        <div className="d-flex justify-content-center">
          <Pagination className="pagination-success d-flex justify-content-center">
            <Pagination.Prev
              disabled={meta.Page <= meta['Total-Pages']}
              onClick={() => {
                if (meta.Page > meta.Total) {
                  setCurrentPage(meta.Page - 1)
                }
              }}
            />
            {Array.from(new Array(meta['Total-Pages'] || 0)).map((_, key) => (
              <Pagination.Item active={currentPage === key + 1} key={key} onClick={() => setCurrentPage(key + 1)}>
                {key + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={meta.Page >= meta.Total}
              onClick={() => {
                if (meta.Page < meta.Total) {
                  setCurrentPage(meta.Page + 1)
                }
              }}
            />
          </Pagination>
        </div>
      )}
    </>
  )
}

export default PaginationComponent
