import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import JScookie from 'js-cookie'
import { login } from 'services/actions/session'
import { useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Login = () => {
  const hasAuthCookie = JScookie.get('--app-access-token')
  const session = useSelector(state => state.session)
  const Dispatch = useDispatch()
  const [form, setForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
    email: '',
    password: '',
    namespace: 'admin',
    grant_type: 'password',
  })

  return (
    <div>
      {hasAuthCookie && <Redirect to="/admin/applications" />}
      <div className="d-flex align-items-stretch auth auth-img-bg" style={{ height: '100vh' }}>
        <div className="row flex-grow">
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div className="auth-form-transparent text-left p-3">
              <div className="brand-logo">
                <img src="/assets/images/logo-dark.png" alt="logo" style={{ height: 72, width: 'unset' }} />
                {/* <h2 className="text-dark font-weight-bold">ByondPay</h2> */}
              </div>
              <h4>Welcome back!</h4>
              <h6 className="font-weight-light">Happy to see you again!</h6>
              {(session.status === 'failed' || session.status === 'success') && (
                <div className={`alert alert-${session.status === 'failed' ? 'danger' : 'success'}`}>
                  <span>{session.message}</span>
                </div>
              )}

              <form
                className="pt-3"
                onSubmit={e => {
                  e.preventDefault()
                  Dispatch(login(form))
                }}
              >
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="exampleInputEmail"
                    placeholder="Insert email"
                    required
                    value={form.email || ''}
                    onChange={e => setForm({ email: e.currentTarget.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder="Password"
                    required
                    value={form.password || ''}
                    onChange={e => setForm({ password: e.currentTarget.value })}
                  />
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  {/* <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      <i className="input-helper"></i>
                      Keep me signed in
                    </label>
                  </div> */}
                  {/* <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">
                        Forgot password?
                      </a> */}
                </div>
                <div className="my-3">
                  <button
                    type="submit  "
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    to="/user"
                  >
                    LOGIN
                  </button>
                </div>
                {/* <div className="text-center mt-4 font-weight-light">
                  <span className="text-dark">Don't have an account? </span>
                  <Link to="/" className="text-primary">
                    Create
                  </Link>
                </div> */}
              </form>
            </div>
          </div>
          <div className="col-lg-6 login-half-bg d-flex flex-row"></div>
        </div>
      </div>
    </div>
  )
}

export default Login
