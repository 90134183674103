import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AdminLayout from 'components/layouts/Admin'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { detailCompany, getCompanyStructure } from 'services/actions/company'
import { getApplication } from 'services/actions/application'
import AuthServiceProvider from 'providers/auth-service'
import _ from 'lodash'
import CompanyInformation from './company-information'
import CompanyFinancial from './financial-data'
import CompanyDocument from './company-document'

const ApplicationDetail = () => {
  const [tabs, setTab] = useState('company')
  const Dispatch = useDispatch()
  const { id, appId } = useParams()
  const applications = useSelector(state => state.application.data)

  const currentApplication = applications?.find(itm => itm.id == appId || itm?.company?.id == id)

  useEffect(() => {
    Dispatch(
      getApplication({
        page: 1,
      })
    )
  }, [])

  return (
    <AuthServiceProvider>
      <AdminLayout>
        <div className="pb-5">
          <Link to="/admin/applications" className="text-decoration-none text-dark btn-back-action">
            <i className="mdi  mdi-chevron-left text-dark"></i>
            <span>Back</span>
          </Link>
          <div className="row">
            <div className="col-12 grid-margin mt-4">
              <div className="card">
                <div className="card-body p-0">
                  <div className="w-100 row p-4">
                    <div className="col-xl-5 col-md-6">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td style={{ minWidth: 300 }}>
                              <span className="font-weight-bold text-dark">Company Name</span>
                            </td>
                            <td>:</td>
                            <td>
                              <span className="text-dark">{currentApplication?.company?.name || '-'}</span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ minWidth: 300 }}>
                              <span className="font-weight-bold text-dark">Company Registration Number</span>
                            </td>
                            <td>:</td>
                            <td>
                              <span className="text-dark">{currentApplication?.company?.registration_id || '-'}</span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ minWidth: 300 }}>
                              <span className="font-weight-bold text-dark">Appliation ID</span>
                            </td>
                            <td>:</td>
                            <td>
                              <span className="text-dark">{currentApplication?.application_id || '-'}</span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ minWidth: 300 }}>
                              <span className="font-weight-bold text-dark">Status</span>
                            </td>
                            <td>:</td>
                            <td>
                              <span className="badge badge-primary">{currentApplication?.state || '-'}</span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ minWidth: 300 }}>
                              <span className="font-weight-bold text-dark">Tags</span>
                            </td>
                            <td>:</td>
                            <td>
                              <span className="text-dark">{(currentApplication?.tag_list || []).join(', ')}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="w-100 border-top"></div>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-lg-8 mx-auto">
                        <div className="btn-group w-100" role="group">
                          <button
                            type="button"
                            className={`btn ${
                              tabs === 'company'
                                ? 'btn-success font-weight-bold'
                                : 'btn-outline-secondary font-weight-normal'
                            }  btn-block mt-0 `}
                            onClick={() => setTab('company')}
                          >
                            Company Information
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              tabs === 'financial'
                                ? 'btn-success font-weight-bold'
                                : 'btn-outline-secondary font-weight-normal'
                            }  btn-block mt-0`}
                            onClick={() => setTab('financial')}
                          >
                            Financial Data
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              tabs === 'document'
                                ? 'btn-success font-weight-bold'
                                : 'btn-outline-secondary font-weight-normal'
                            }  btn-block mt-0`}
                            onClick={() => setTab('document')}
                          >
                            Document Storage
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          {tabs === 'company' ? (
                            <CompanyInformation application={currentApplication} />
                          ) : tabs === 'financial' ? (
                            <CompanyFinancial application={currentApplication} />
                          ) : (
                            <CompanyDocument application={currentApplication} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </AuthServiceProvider>
  )
}

export default ApplicationDetail
