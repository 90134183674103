import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap'
import AdminLayout from 'components/layouts/Admin'

export class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <AdminLayout>
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-6 mb-4 mb-xl-0">
                  <h3>Welcome Edwin!</h3>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex align-items-center justify-content-md-end">
                    <div className="border-right-dark pr-4 mb-3 mb-xl-0 d-xl-block d-none">
                      <p className="text-muted">Today</p>
                      <h6 className="font-weight-medium text-dark mb-0">23 Aug 2019</h6>
                    </div>
                    <div className="pr-1 mb-3 mb-xl-0">
                      <button type="button" className="btn btn-success btn-icon mr-2">
                        <i className="mdi mdi-refresh"></i>
                      </button>
                    </div>
                    <div className="mb-3 mb-xl-0">
                      <Dropdown>
                        <Dropdown.Toggle className="btn btn-success">14 Aug 2019</Dropdown.Toggle>

                        <Dropdown.Menu className="preview-list navbar-dropdown">
                          <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                            <p>2015</p>
                          </Dropdown.Item>
                          <div className="dropdown-divider"></div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-header-tab mt-xl-4">
                <div className="col-12 pl-0 pr-0">
                  <div className="row ">
                    <div className="col-12 col-sm-6 mb-xs-4  pt-2 pb-2 mb-xl-0">
                      <ul className="nav nav-tabs tab-transparent border-0" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="overview-tab"
                            data-toggle="tab"
                            href="!#"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                          >
                            Overview
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="users-tab"
                            data-toggle="tab"
                            href="!#"
                            role="tab"
                            aria-controls="users"
                            aria-selected="false"
                          >
                            Users
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="returns-tab"
                            data-toggle="tab"
                            href="!#"
                            role="tab"
                            aria-controls="returns"
                            aria-selected="false"
                          >
                            More
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-sm-6 mb-xs-4 mb-xl-0 pt-2 pb-2 text-md-right d-none d-md-block">
                      <div className="d-inline-flex">
                        <button className="btn d-flex align-items-center text-dark">
                          <i className="mdi mdi-download mr-1"></i>
                          <span className="text-left font-weight-medium">Download report</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content tab-transparent-content pb-0">
                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-wrap justify-content-between">
                            <h4 className="card-title">Approved Amount</h4>
                          </div>
                          <div>
                            <div className="d-flex flex-wrap align-items-baseline">
                              <h2 className="mr-3">$ 27632</h2>
                              <h3 className="text-success">+2.3%</h3>
                            </div>
                            <div className="mb-3">
                              <p className="text-muted font-weight-bold text-small">
                                North Ludwig <span className=" font-weight-normal">($2643M last month)</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-wrap justify-content-between">
                            <h4 className="card-title">Distributed Amount vs Remaining</h4>
                          </div>
                          <div>
                            <div className="d-flex flex-wrap align-items-baseline">
                              <h2 className="mr-3">$ 27632</h2>
                              <h3 className="text-success">+2.3%</h3>
                            </div>
                            <div className="mb-3">
                              <p className="text-muted font-weight-bold text-small">
                                North Ludwig <span className=" font-weight-normal">($2643M last month)</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-wrap justify-content-between">
                            <h4 className="card-title">Loan Payment</h4>
                          </div>
                          <div>
                            <div className="d-flex flex-wrap align-items-baseline">
                              <h2 className="mr-3">$ 27632</h2>
                              <h3 className="text-success">+2.3%</h3>
                            </div>
                            <div className="mb-3">
                              <p className="text-muted font-weight-bold text-small">
                                North Ludwig <span className=" font-weight-normal">($2643M last month)</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="row h-100">
                        <div className="col-12">
                          <div className="card h-100">
                            <div className="card-body">
                              <div className="d-flex flex-wrap justify-content-between">
                                <h4 className="card-title">Comments</h4>
                              </div>
                              <div className="container-fluid">
                                <div className="row ticket-card mt-3 pb-2 pb-3 mb-3 aling-items-center">
                                  <div className="col-md-3">
                                    <img
                                      className="img-sm rounded-circle mb-4 mb-md-0"
                                      src={require('assets/images/faces/face29.jpg')}
                                      alt="profile"
                                    />
                                  </div>
                                  <div className="ticket-details col-md-9 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                      <h4 className="text-dark mr-2 no-wrap">Dustin Lucas</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row h-100">
                        <div className="col-12 h-100">
                          <div className="card h-100">
                            <div className="card-body">
                              <div className="d-flex flex-wrap justify-content-between">
                                <h4 className="card-title">Comments</h4>
                              </div>
                              <div className="container-fluid">
                                <div className="row ticket-card mt-3 pb-2 border-bottom pb-3 mb-3">
                                  <div className="col-md-1">
                                    <img
                                      className="img-sm rounded-circle mb-4 mb-md-0"
                                      src={require('assets/images/faces/face29.jpg')}
                                      alt="profile"
                                    />
                                  </div>
                                  <div className="ticket-details col-md-9">
                                    <div className="d-md-flex">
                                      <h4 className="text-dark mr-2 no-wrap">Dustin Lucas</h4>
                                    </div>
                                    <p className="text-gray font-weight-medium">
                                      People who have a ticket reservation of the event is automatically mark as
                                      interested. Harness The Power Of Words In
                                    </p>
                                    <div className="row text-muted d-md-flex d-none">
                                      <div className="col-12 d-flex">
                                        <p className="mb-0 mr-2 text-gray text-small">30 Min ago - Due in 1 days</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12 grid-margin">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-wrap justify-content-between">
                            <h4 className="card-title">Schedule Meetings</h4>
                          </div>
                          <div className="table-responsive">
                            <table className="table center-aligned-table">
                              <thead>
                                <tr>
                                  <th>
                                    <span className="font-weight-bold">Topic</span>
                                  </th>
                                  <th>
                                    <span className="font-weight-bold">Date</span>
                                  </th>
                                  <th>
                                    <span className="font-weight-bold">Time</span>
                                  </th>
                                  <th>
                                    <span className="font-weight-bold">Creator</span>
                                  </th>
                                  <th>
                                    <span className="font-weight-bold">Actions</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <span className="text-dark">Example</span>
                                  </td>
                                  <td>18 May 2019</td>
                                  <td>12:12</td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={require('assets/images/orders/order-1.jpg')}
                                        alt="order"
                                        className="img-rect mr-2"
                                      />
                                      <span className="ml-2">Admin</span>
                                    </div>
                                  </td>
                                  <td>
                                    <a href="!#" className="btn btn-outline-success">
                                      <span>View Detail</span>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                  Tab Item
                </div>
                <div className="tab-pane fade" id="returns-1" role="tabpanel" aria-labelledby="returns-tab">
                  Tab Item
                </div>
                <div className="tab-pane fade" id="more" role="tabpanel" aria-labelledby="more-tab">
                  Tab Item
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <h4 className="text">Deliverables</h4>
                  <ul className="list-group list-group-flush ">
                    <li className="list-group-item border-0 d-flex px-0">
                      <span className="mr-auto text-dark">Documents</span>
                      <span className="ml-auto font-weight-bold text-dark">20</span>
                    </li>
                    <li className="list-group-item border-0 d-flex px-0">
                      <span className="mr-auto text-dark">Upload Blank Statement</span>
                      <span className="ml-auto font-weight-bold text-dark">20</span>
                    </li>
                    <li className="list-group-item border-0 d-flex px-0">
                      <span className="mr-auto text-dark">Business Plan</span>
                      <span className="ml-auto font-weight-bold text-dark">20</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <h4 className="text">Milestones</h4>
                  <ul className="list-group list-group-flush milestone-parent">
                    <li className="list-group-item border-0 d-flex px-0 milestone-item">
                      <div className="milestone-icon">
                        <i className="mdi mdi-package-variant-closed text-dark"></i>
                      </div>
                      <div className="milestone-detail">
                        <h4 className="font-weight-bold">Online Purchases</h4>
                        <p className="text-secondary">Lorem ipsum dolor sit amet,</p>
                        <span className="text-secondary">10 Min Ago</span>
                      </div>
                    </li>
                    <li className="list-group-item border-0 d-flex px-0 milestone-item">
                      <div className="milestone-icon">
                        <i className="mdi mdi-package-variant-closed text-dark"></i>
                      </div>
                      <div className="milestone-detail">
                        <h4 className="font-weight-bold">Online Purchases</h4>
                        <p className="text-secondary">Lorem ipsum dolor sit amet,</p>
                        <span className="text-secondary">10 Min Ago</span>
                      </div>
                    </li>
                    <li className="list-group-item border-0 d-flex px-0 milestone-item">
                      <div className="milestone-icon">
                        <i className="mdi mdi-package-variant-closed text-dark"></i>
                      </div>
                      <div className="milestone-detail">
                        <h4 className="font-weight-bold">Online Purchases</h4>
                        <p className="text-secondary">Lorem ipsum dolor sit amet,</p>
                        <span className="text-secondary">10 Min Ago</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    )
  }
}

export default Dashboard
