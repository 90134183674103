import { APPLICATION } from '../constans/application'

const initialState = {
  message: null,
  status: null,
  data: [],
}

const application = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION.GET_APPLICATION:
      return {
        ...state,
        status: 'request',
      }
    case APPLICATION.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        status: 'success',
        data: action.payload.data.data,
        meta: action.payload.data.meta,
      }
    case APPLICATION.GET_APPLICATION_FAILED:
      return {
        ...state,
        status: 'failed',
        data: [],
      }
    // case APPLICATION.APPROVE_APPLICATION:
    //   return {
    //     ...state,
    //     status: 'failed',
    //   }
    default:
      return state
  }
}

export default application
