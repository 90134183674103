import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getFinancialReport } from 'services/actions/company'
import _ from 'lodash'
import moment from 'moment'

const FinancialData = ({ application }) => {
  const Dispatch = useDispatch()
  const financial = useSelector(state => state.company.financial)

  useEffect(() => {
    Dispatch(getFinancialReport(application.id))
  }, [])

  return (
    <div className="col-md-8 col-12 mx-auto mt-4">
      {financial?.map((fr, frKey) => (
        <div key={frKey}>
          <div className="w-100 border-bottom">
            <h3 className="my-3">
              {_.startCase(_.lowerCase(fr.name.replaceAll('_', ' ')))} {fr.number ? ' : ' : ''} {fr.number || ''}{' '}
            </h3>
          </div>
          <div className="mt-3 mb-4">
            <table>
              <tbody>
                {fr.records?.map((order, orderKey) => (
                  <tr key={orderKey}>
                    <td style={{ width: 200 }}>
                      <span className="font-weight-bold text-dark">{order.period}</span>
                    </td>
                    <td style={{ width: 20 }}>:</td>
                    <td>
                      <span className="text-dark">{order.number}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FinancialData
