import React, { useState, useEffect } from 'react'
import { Link, withRouter, useLocation } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const Sidebar = () => {
  const [menu, setMenu] = useState({})
  const location = useLocation()
  const user = useSelector(state => state.session.user)

  const handleRouteChange = () => {
    document.querySelector('#sidebar').classList.remove('active')
    Object.keys(menu).forEach(i => {
      setMenu({ [i]: false })
    })
  }
  const isPathActive = (path, exact = false) => {
    return exact ? location.pathname === path : location.pathname.startsWith(path)
  }

  useEffect(() => {
    handleRouteChange()
    const body = document.querySelector('body')
    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: 0; overflow: hidden;');
          // }
        }
      })
    })
  }, [])

  useEffect(() => {
    handleRouteChange()
  }, [location])

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <div className="nav-link d-flex">
            <div className="profile-image">
              <img src="/assets/images/user.svg" alt="profile" />
            </div>
            <div className="profile-name">
              <p className="name">{_.startCase(user?.profile?.name || user?.email)}</p>
            </div>
          </div>
        </li>
        {/* <li className={isPathActive('/admin', true) ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin">
            <i className="mdi mdi-home menu-icon"></i>
            <span className="menu-title">
              <Trans>Dashboard</Trans>
            </span>
          </Link>
        </li> */}
        <li className={isPathActive('/admin/scorecard') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin/scorecard">
            <i className="mdi mdi-chart-tree menu-icon"></i>
            <span className="menu-title">
              <Trans>Scorecard</Trans>
            </span>
          </Link>
        </li>
        <li className={isPathActive('/admin/applications') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin/applications">
            <i className="mdi mdi-file-chart menu-icon"></i>
            <span className="menu-title">
              <Trans>Application</Trans>
            </span>
          </Link>
        </li>
        <li className={isPathActive('/admin/company') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin/company">
            <i className="mdi mdi-city menu-icon"></i>
            <span className="menu-title">
              <Trans>Companies</Trans>
            </span>
          </Link>
        </li>
        <li className={isPathActive('/admin/taging') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin/taging">
            <i className="mdi mdi-tag menu-icon"></i>
            <span className="menu-title">
              <Trans>Taging</Trans>
            </span>
          </Link>
        </li>
        <li className={isPathActive('/admin/users') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin/users">
            <i className="mdi mdi-account-circle menu-icon"></i>
            <span className="menu-title">
              <Trans>Users</Trans>
            </span>
          </Link>
        </li>
        {/* <li className={isPathActive('/admin/admins') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin/admins">
            <i className="mdi mdi-star-circle menu-icon"></i>
            <span className="menu-title">
              <Trans>Admins</Trans>
            </span>
          </Link>
        </li> */}
        <li className={isPathActive('/admin/setting') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/admin/setting">
            <i className="mdi mdi-settings menu-icon"></i>
            <span className="menu-title">
              <Trans>Setting</Trans>
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default withRouter(Sidebar)
