import { TAG } from 'services/constans/tag'

const initialState = {
  message: null,
  status: null,
  data: [],
  meta: {},
}

const tag = (state = initialState, action) => {
  switch (action.type) {
    case TAG.GET_TAG:
      return {
        ...state,
        status: 'request',
        data: [],
      }
    case TAG.GET_TAG_SUCCESS:
      return {
        ...state,
        status: 'request',
        data: action?.payload?.data?.data,
        meta: action?.payload?.data?.meta,
      }
    case TAG.GET_TAG_FAILED:
      return {
        ...state,
        status: 'failed',
        message: action?.payload?.data?.message,
      }
    case TAG.CREATE_TAG:
      return {
        ...state,
        status: 'request',
      }
    case TAG.CREATE_TAG_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action?.payload?.data?.message,
      }
    case TAG.CREATE_TAG_FAILED:
      return {
        ...state,
        status: 'failed',
        data: [],
        message: action?.payload?.data?.message,
      }
    case TAG.UPDATE_TAG:
      return {
        ...state,
        status: 'request',
      }
    case TAG.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action?.payload?.data?.message,
      }
    case TAG.UPDATE_TAG_FAILED:
      return {
        ...state,
        status: 'failed',
        data: [],
        message: action?.payload?.data?.message,
      }
    case TAG.DELETE_TAG:
      return {
        ...state,
        status: 'request',
      }
    case TAG.DELETE_TAG_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action?.payload?.data?.message,
      }
    case TAG.DELETE_TAG_FAILED:
      return {
        ...state,
        status: 'failed',
        data: [],
        message: action?.payload?.data?.message,
      }
    default:
      return state
  }
}

export default tag
