import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCompanyDocument } from 'services/actions/company'

const CompanyDocument = ({ application }) => {
  const Dispatch = useDispatch()

  const companyDocument = useSelector(state => state.company.document)

  useEffect(() => {
    Dispatch(getCompanyDocument(application.id))
  }, [])

  return (
    <div className="col-md-8 col-12 mx-auto mt-4">
      {/* {console.log(companyDocument, fakeDocs)} */}
      <table className="table">
        <tbody>
          {companyDocument?.map((doc, key) => (
            <tr key={key}>
              <td>
                <span className="font-weight-bold text-dark">
                  {doc?.file_data?.metadata?.filename || doc?.uploadable_type?.match(/[A-Z][a-z]+/g)?.join(' ')}
                </span>
              </td>
              <td>:</td>
              <td colSpan="2">
                <img
                  src={!doc.file_url?.includes('http') ? `https://${doc.file_url}` : doc.file_url}
                  alt=""
                  style={{ objectFit: 'cover', borderRadius: 0, height: 150, width: 250 }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CompanyDocument
