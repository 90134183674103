import { useReducer, useState, useEffect } from 'react'
import AdminLayout from 'components/layouts/Admin'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from 'services/actions/session'
const PHONE_CODE = ['+60', '+62', '+63']

const DashboardAccount = () => {
  const [newPasswordHide, setNewPasswordHide] = useState(true)
  const [confirmPasswordHide, setConfirmPasswordHide] = useState(true)
  const user = useSelector(state => state.session.user)
  const session = useSelector(state => state.session)
  const Dispatch = useDispatch()
  const [form, setForm] = useReducer((form, newForm) => ({ ...form, ...newForm }), {
    email: user?.email,
    id: user?.id,
    profile_id: user?.profile?.id,
    name: user?.profile?.name,
    phoneCode: PHONE_CODE.find(itm => user?.phone?.indexOf(itm)) || '',
    phone: user?.profile?.mobile_phone_number,
    password: null,
    password_confirmation: null,
    otp_required_for_login: user?.otp_required_for_login,
  })

  useEffect(() => {
    setForm({
      email: user?.email,
      id: user?.id,
      profile_id: user?.profile?.id,
      name: user?.profile?.name,
      phoneCode: PHONE_CODE.find(itm => user?.phone?.indexOf(itm)) || '',
      phone: user?.profile?.mobile_phone_number,
      password: null,
      password_confirmation: null,
      enableOtp: user?.otp_required_for_login,
    })
  }, [user])

  const handleuserSubmit = e => {
    e.preventDefault()
    Dispatch(
      updateUser({
        email: form.email,
        password: form.password,
        password_confirmation: form.password_confirmation,
        otp_required_for_login: form?.otp_required_for_login,
        profile_attributes: {
          id: form.profile_id,
          name: form.name,
          mobile_phone_number: form.phoneCode + form.phone,
        },
      })
    )
  }

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-7 col-md-8 mx-auto">
                  {/* <div className="d-flex flex-column  justify-content-center pb-4 mx-auto">
                    <img
                      src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652_1280.png"
                      alt="profile"
                      className="img-lg rounded mb-3 mx-auto"
                    />
                    <label className="mt-2 mx-auto">
                      <i className="mdi mdi-camera text-success"></i>
                      <span className="ml-2 text-success">Upload Photo</span>
                      <input type="file" className="d-none" />
                    </label>
                  </div> */}
                  <div className="w-100">
                    {(session.status === 'failed' || session.status === 'success') && (
                      <div className={`alert alert-${session.status === 'failed' ? 'danger' : 'success'}`}>
                        <span>{session.message}</span>
                      </div>
                    )}

                    <form onSubmit={handleuserSubmit}>
                      <h3 className="font-weight-normal text-secondary">Account Setting</h3>
                      <div className="border-top w-100 mb-3"></div>
                      <div className="form-group mb-2">
                        <label className="col-form-label text-dark font-weight-bold mb-0">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          value={form.name || ''}
                          onChange={e =>
                            setForm({
                              name: e.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label className="col-form-label text-dark font-weight-bold mb-0">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          value={form.email || ''}
                          onChange={e =>
                            setForm({
                              email: e.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label text-dark font-weight-bold mb-0">Phone Number</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select
                              className="form-control  bg-lighten"
                              value={form.phoneCode}
                              onChange={e => {
                                setForm({ phoneCode: e.currentTarget.value })
                              }}
                            >
                              {PHONE_CODE.map((itm, key) => (
                                <option value={itm} key={key}>
                                  {itm}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Phone NUmber"
                            value={PHONE_CODE.reduce((acc, cp) => acc?.replace(cp, ''), form?.phone || '')}
                            aria-describedby="basic-addon1"
                            onChange={e => {
                              setForm({ phone: e.currentTarget.value })
                            }}
                          />
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            checked={form?.enableOtp || false}
                            onChange={e => {
                              setForm({
                                enableOtp: e.currentTarget.checked,
                              })
                            }}
                          />
                          <label className="custom-control-label" for="customCheck1">
                            <span className="d-block mt-1 ml-2">Enable OTP when login</span>
                          </label>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <button
                          className="btn btn-outline-success"
                          onClick={() => {
                            setForm({
                              email: user?.email,
                              id: user?.id,
                              profile_id: user?.profile?.id,
                              name: user?.profile?.name,
                              phoneCode: PHONE_CODE.find(itm => user?.phone?.indexOf(itm)) || '',
                              phone: user?.profile?.mobile_phone_number,
                              password: null,
                              password_confirmation: null,
                              enableOtp: user?.otp_required_for_login,
                            })
                          }}
                        >
                          Cancel
                        </button>
                        <button className="btn btn-success ml-3">Save</button>
                      </div>
                    </form>
                  </div>

                  <div className="w-100 mt-4 pb-4">
                    <form onSubmit={handleuserSubmit}>
                      <h3 className="font-weight-normal text-secondary">Change Password</h3>
                      <div className="border-top w-100 mb-3"></div>

                      <div className="form-group mb-2">
                        <label htmlFor="" className="col-form-label font-weight-bold text-dark mb-0">
                          Create New Password
                        </label>
                        <div className="password-input">
                          <input
                            type={newPasswordHide ? 'password' : 'text'}
                            className="form-control"
                            value={form?.password || ''}
                            onChange={e =>
                              setForm({
                                password: e.currentTarget.value,
                              })
                            }
                          />
                          <button className="btn p-0" onClick={() => setNewPasswordHide(!newPasswordHide)}>
                            <i className={`mdi ${newPasswordHide ? 'mdi-eye-off' : 'mdi-eye'} text-secondary`}></i>
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label font-weight-bold text-dark mb-0">
                          Confirm New Password
                        </label>
                        <div className="password-input">
                          <input
                            type={confirmPasswordHide ? 'password' : 'text'}
                            className="form-control"
                            value={form?.password_confirmation || ''}
                            onChange={e =>
                              setForm({
                                password_confirmation: e.currentTarget.value,
                              })
                            }
                          />
                          <button className="btn p-0" onClick={() => setConfirmPasswordHide(!confirmPasswordHide)}>
                            <i className={`mdi ${confirmPasswordHide ? 'mdi-eye-off' : 'mdi-eye'} text-secondary`}></i>
                          </button>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <button
                          className="btn btn-outline-success"
                          onClick={() => {
                            setForm({
                              email: user?.email,
                              id: user?.id,
                              profile_id: user?.profile?.id,
                              name: user?.profile?.name,
                              phoneCode: PHONE_CODE.find(itm => user?.phone?.indexOf(itm)) || '',
                              phone: user?.profile?.mobile_phone_number,
                              password: null,
                              password_confirmation: null,
                              enableOtp: user?.otp_required_for_login,
                            })
                          }}
                        >
                          Cancel
                        </button>
                        <button className="btn btn-success  ml-3" type="submit">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default DashboardAccount
