import { useState, useEffect, useReducer } from 'react'
import AdminLayout from 'components/layouts/Admin'
import { Link } from 'react-router-dom'
import AuthServiceProvider from 'providers/auth-service'
import Pagination from 'components/shared/pagination'
import { getTag, deleteTag } from 'services/actions/tag'
import { useSelector, useDispatch } from 'react-redux'
import CreateTag from './create'

const Tag = () => {
  const tags = useSelector(state => state.tag?.data)
  const meta = useSelector(state => state.tag?.meta)
  const [currentPage, setCurrentPage] = useState(1)
  const Dispatch = useDispatch()

  const [tab, setTab] = useState({ name: 'index' })

  useEffect(() => {
    Dispatch(
      getTag({
        page: currentPage,
      })
    )
  }, [currentPage])

  return (
    <AuthServiceProvider>
      {tab.name === 'index' && (
        <AdminLayout>
          <div className="row">
            {/* <div className="col-12  d-none">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="inlineFormInputGroup"
                        placeholder="tag Name"
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-lg btn-outline-success btn-block"
                        onClick={() => setTab({ name: 'create' })}
                      >
                        <i className="mdi mdi-plus"></i>
                        New Tags
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12 grid-margin mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="w-100 d-flex justify-content-end">
                    <div className="col-md-2">
                      <button
                        className="btn d-flex justify-content-center px-2 align-items-center btn-outline-success btn-block"
                        type="button"
                        onClick={() => setTab({ name: 'create' })}
                      >
                        <i className="mdi mdi-plus"></i>
                        New Tags
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title">
                      <span className="text-success">125</span> Tags
                    </h4>
                  </div>
                  <div className="table-responsive">
                    <table className="table center-aligned-table">
                      <thead>
                        <tr>
                          <th>
                            <span className="font-weight-bold">Tags</span>
                          </th>
                          <th>
                            <span className="font-weight-bold">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tags?.map((tg, key) => (
                          <tr key={key}>
                            <td className="pl-0" style={{ width: '100%' }}>
                              <span className="font-weight-bold">#{tg.name}</span>
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-success btn-sm"
                                onClick={() => setTab({ name: 'create', attributes: tg })}
                              >
                                <i className="mdi mdi-pencil"></i>
                                <span>Edit Tags</span>
                              </button>
                              <button
                                className="btn btn-outline-danger ml-3 btn-sm"
                                onClick={() => Dispatch(deleteTag(tg.id))}
                              >
                                <i className="mdi mdi-delete"></i>
                                <span>Remove</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination here */}
                  <Pagination meta={meta} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
              </div>
            </div>
          </div>
        </AdminLayout>
      )}
      {tab.name === 'create' && <CreateTag tab={tab} setTab={setTab} />}
    </AuthServiceProvider>
  )
}

export default Tag
