import { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getApplication,scoreCard } from 'services/actions/application'
import AdminLayout from 'components/layouts/Admin'
import AuthServiceProvider from 'providers/auth-service'
import moment from 'moment'
import TableauReport from 'tableau-react';

const ScoreBoard = () => {
  const Dispatch = useDispatch()

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [data, setData] = useState({});
  const [ticket, setTicket] = useState(false);
  const [url, setUrl] = useState('');

  const [currentPage, setCurrentPage] = useState(1)

  const options = {
    width: '1000px',
    height:'750px'
  };

  
  const SimpleReport = (props) => {
    return (props.token)?
         <center> <TableauReport
          url="https://report2.claritascloud.com/t/eatcosys/views/pncloan_customer_scorecard_demo/PNC" 
          token={props.token}
          query="?:embed=yes&:comments=no&:toolbar=no&:refresh=yes"
          options={options}
        /></center> :'Loading ...';
  };


  useEffect(() => {


    fetch("https://tableau.byondpay.com.my/getTicket.php?ajax=true&type=2")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setTicket(result.ticket);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  return (
    <AuthServiceProvider>
      <AdminLayout>
        <div>
       <SimpleReport token={ticket} />
         </div>
      </AdminLayout>
    </AuthServiceProvider>
  )
}

export default ScoreBoard
