import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import session from './session'
import tag from './tag'
import application from './application'
import company from './company'
import user from './user'

export default combineReducers({
  session,
  tag,
  company,
  application,
  user,
})
