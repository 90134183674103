import { put, takeLatest, call, fork } from '@redux-saga/core/effects'
import { getUserSucces, getUserFailed } from 'services/actions/user'
import { USER } from 'services/constans/user'
import { getUser } from 'services/apis/user'

function* getUserSaga() {
  const getUserRequest = yield call(getUser)
  if (getUserRequest.ok) {
    yield put(getUserSucces(getUserRequest))
  } else {
    yield put(getUserFailed(getUserRequest))
  }
}

function* watchSaga() {
  yield takeLatest(USER.GET_USER, getUserSaga)
}

export default watchSaga
