import { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getApplication, rejectApplication, approveApplication } from 'services/actions/application'
import AdminLayout from 'components/layouts/Admin'
import AuthServiceProvider from 'providers/auth-service'
import moment from 'moment'

const UserApplication = () => {
  const Dispatch = useDispatch()

  const applications = useSelector(state => state.application.data)
  const meta = useSelector(state => state.application.meta)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    Dispatch(
      getApplication({
        page: currentPage,
      })
    )
  }, [currentPage])

  return (
    <AuthServiceProvider>
      <AdminLayout>
        <div>
          <div className="row">
            {/* <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group d-flex mb-0">
                        <label htmlFor="application-filter-type" className="col-form-label text-dark font-weight-bold">
                          Status
                        </label>
                        <select name="" id="application-filter-type" className="form-control form-control-lg ml-3">
                          <option value="">All</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group d-flex mb-0">
                        <label
                          htmlFor="applicaiton-id"
                          className="col-form-label text-dark font-weight-bold"
                          style={{ width: 190 }}
                        >
                          Application ID
                        </label>
                        <input
                          name=""
                          id="applicaiton-id"
                          className="form-control form-control-lg ml-3"
                          placeholder="Enter Number ID ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="sr-only" htmlFor="inlineFormInputGroup">
                        Username
                      </label>
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="inlineFormInputGroup"
                          placeholder="Search Company ..."
                        />
                        <div className="input-group-append">
                          <button className="btn btn-success font-weight-bold">Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12 grid-margin mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4 className="card-title">
                      <span className="text-success">{applications?.length || 0}</span> Applications
                    </h4>
                    {/* <div className="ml-auto">
                      <button className="btn btn-outline-success d-flex align-items-center btn-lg px-3">
                        <i className="fa fa-file-excel-o"></i>
                        <span className="ml-2 ">Export CSV</span>
                      </button>
                    </div> */}
                  </div>
                  <div className="table-responsive">
                    <table className="table center-aligned-table">
                      <thead>
                        <tr>
                          <th>
                            <span className="font-weight-bold">Company</span>
                          </th>
                          <th>
                            <span className="font-weight-bold">Application ID</span>
                          </th>
                          <th>
                            <span className="font-weight-bold">Updated</span>
                          </th>
                          <th>
                            <span className="font-weight-bold">Status</span>
                          </th>
                          <th>
                            <span className="font-weight-bold">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {applications?.map((app, key) => (
                          <tr key={key}>
                            <td className="pl-0">{app?.company?.name}</td>
                            <td>{app.application_id}</td>
                            <td>{moment(app.updated_at).format('YYYY-MM-DD hh:mm A')}</td>
                            <td>
                              <div className="badge badge-primary">{app.state}</div>
                            </td>
                            <td>
                              {app.state === 'submited' && (
                                <>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => Dispatch(rejectApplication(app.id))}
                                  >
                                    Reject
                                  </button>
                                  <button
                                    className="btn btn-success mx-2 btn-sm"
                                    onClick={() => Dispatch(approveApplication(app.id))}
                                  >
                                    Approve
                                  </button>
                                </>
                              )}

                              <Link
                                to={`/admin/applications/${app?.company?.id}/${app?.id}`}
                                className="btn btn-outline-success btn-sm"
                              >
                                <span>View Detail</span>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {meta?.Total > 0 && (
                    <div className="d-flex justify-content-center">
                      <Pagination className="pagination-success d-flex justify-content-center">
                        <Pagination.Prev
                          disabled={meta.Page <= meta.Total}
                          onClick={() => {
                            if (meta.Page > meta.Total) {
                              setCurrentPage(meta.Page - 1)
                            }
                          }}
                        />
                        {Array.from(new Array(meta?.Total || 0)).map((_, key) => (
                          <Pagination.Item
                            active={currentPage === key + 1}
                            key={key}
                            onClick={() => setCurrentPage(key + 1)}
                          >
                            {key + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          disabled={meta.Page >= meta.Total}
                          onClick={() => {
                            if (meta.Page < meta.Total) {
                              setCurrentPage(meta.Page + 1)
                            }
                          }}
                        />
                      </Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </AuthServiceProvider>
  )
}

export default UserApplication
