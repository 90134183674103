import Login from 'pages/public/login'
import RegisterOtp from 'pages/public/otp'

// import Admin

import adminDashboard from 'pages/admin/index'
import adminApplication from 'pages/admin/application/index'
import adminApplicationDetail from 'pages/admin/application/detail'
import adminCompany from 'pages/admin/company/index'
import adminTaging from 'pages/admin/taging/index'
import adminTagingCreate from 'pages/admin/taging/create'
import adminUser from 'pages/admin/user/index'
import adminAdmin from 'pages/admin/admin/index'
import adminSetting from 'pages/admin/setting/index'
import scoreBoard from 'pages/admin/application/score-board'

const routes = [
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/otp',
    exact: true,
    component: RegisterOtp,
  },
  {
    path: '/',
    exact: true,
    redirect: '/login',
  },
  // {
  //   path: '/admin',
  //   exact: true,
  //   component: adminDashboard,
  // },
  {
    path: '/admin/applications',
    exact: true,
    component: adminApplication,
  },
  {
    path: '/admin/scorecard',
    exact: true,
    component: scoreBoard,
  },
  {
    path: '/admin/applications/:id/:appId',
    exact: true,
    component: adminApplicationDetail,
  },
  {
    path: '/admin/company',
    exact: true,
    component: adminCompany,
  },
  {
    path: '/admin/taging',
    exact: true,
    component: adminTaging,
  },
  {
    path: '/admin/taging/create',
    exact: true,
    component: adminTagingCreate,
  },
  {
    path: '/admin/taging/:id',
    exact: true,
    component: adminTagingCreate,
  },
  {
    path: '/admin/users',
    exact: true,
    component: adminUser,
  },
  // {
  //   path: '/admin/admins',
  //   exact: true,
  //   component: adminAdmin,
  // },
  {
    path: '/admin/setting',
    exact: true,
    component: adminSetting,
  },
]

export default routes
