import { all } from 'redux-saga/effects'

import sessionSaga from './session'
import tag from './tag'
import application from './application'
import company from './company'
import user from './user'

const admins = [tag(), application(), company(), user()]

const users = [sessionSaga()]

function* rootSaga() {
  yield all([...users, ...admins])
}

export default rootSaga
