import { getApplicationSucces, getApplicationFailed } from 'services/actions/application'

import { call, takeLatest, put, fork } from 'redux-saga/effects'
import { getApplications, approveApplication, rejectApplication } from 'services/apis/application'
import { APPLICATION } from 'services/constans/application'

function* getApplicationSaga({ params }) {
  const getApplicationRequest = yield call(getApplications, params)
  if (getApplicationRequest.ok) {
    yield put(getApplicationSucces(getApplicationRequest))
  } else {
    yield put(getApplicationFailed(getApplicationRequest))
  }
}

function* approveApplicationSaga({ id }) {
  yield call(approveApplication, id)
  yield fork(getApplicationSaga, {})
}

function* rejectApplicationSaga({ id }) {
  yield call(rejectApplication, id)
  yield fork(getApplicationSaga, {})
}

function* watchSaga() {
  yield takeLatest(APPLICATION.GET_APPLICATION, getApplicationSaga)
  yield takeLatest(APPLICATION.APPROVE_APPLICATION, approveApplicationSaga)
  yield takeLatest(APPLICATION.REJECT_APPLICATION, rejectApplicationSaga)
}

export default watchSaga
