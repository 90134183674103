import { COMPANY } from '../constans/company'

const initialState = {
  message: null,
  status: null,
  data: [],
  detail: {},
  financial: [],
  documents: {},
}

const company = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY.GET_COMPANY:
      return {
        ...state,
        status: 'request',
      }
    case COMPANY.GET_COMPANY_SUCCESS:
      return {
        ...state,
        status: 'success',
        data: action.payload.data.data,
        meta: action.payload.data.meta,
      }
    case COMPANY.GET_COMPANY_FAILED:
      return {
        ...state,
        status: 'failed',
        data: [],
      }
    // detail company
    case COMPANY.DETAIL_COMPANY:
      return {
        ...state,
        status: 'request',
        detail: {},
      }
    case COMPANY.DETAIL_COMPANY_SUCCESS:
      return {
        ...state,
        status: 'success',
        detail: action.payload.data.data,
      }
    case COMPANY.DETAIL_COMPANY_FAILED:
      return {
        ...state,
        status: 'failed',
        detail: {},
      }
    // detail company
    case COMPANY.GET_COMPANY_STRUCTURE:
      return {
        ...state,
        status: 'request',
        structure: [],
      }
    case COMPANY.GET_COMPANY_STRUCTURES_SUCCESS:
      return {
        ...state,
        status: 'success',
        structure: action.payload.data.data,
      }
    case COMPANY.GET_COMPANY_STRUCTURE_FAILED:
      return {
        ...state,
        status: 'failed',
        structure: [],
      }
    // Financial report
    case COMPANY.GET_COMPANY_FINANCIAL:
      return {
        ...state,
        status: 'request',
        financial: [],
      }
    case COMPANY.GET_COMPANY_FINANCIAL_SUCCESS:
      return {
        ...state,
        status: 'success',
        financial: action.payload.data.data,
      }
    case COMPANY.GET_COMPANY_FINANCIAL_FAILED:
      return {
        ...state,
        status: 'failed',
        financial: [],
      }
    case COMPANY.GET_COMPANY_DOCUMENT:
      return {
        ...state,
        status: 'request',
        document: [],
      }
    case COMPANY.GET_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        status: 'success',
        document: action.payload.data.data,
      }
    case COMPANY.GET_COMPANY_DOCUMENT_FAILED:
      return {
        ...state,
        status: 'success',
        document: [],
      }
    default:
      return state
  }
}

export default company
