import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Navbar from 'components/shared/navbar'
import AdminSidebar from 'components/shared/admin-sidebar'
import { withTranslation } from 'react-i18next'
import AuthServiceProvider from 'providers/auth-service'

class App extends Component {
  state = {}
  componentDidMount() {
    this.onRouteChanged()
  }
  render() {
    return (
      <AuthServiceProvider>
        <div className="container-scroller">
          <Navbar />
          <div className="container-fluid page-body-wrapper">
            <AdminSidebar />
            <div className="main-panel">
              <div className="content-wrapper">{this.props.children}</div>
            </div>
          </div>
        </div>
      </AuthServiceProvider>
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    console.log('ROUTE CHANGED')
    window.scrollTo(0, 0)
  }
}

export default withTranslation()(withRouter(App))
