import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCompanyDocument } from 'services/actions/company'

const CompanyDocument = () => {
  const Dispatch = useDispatch()
  const { id } = useParams()
  const companyDocument = useSelector(state => state.company.document)

  useEffect(() => {
    Dispatch(getCompanyDocument(id))
  }, [])

  return (
    <div className="col-md-8 col-12 mx-auto mt-4">
      <table className="table">
        <tbody>
          {companyDocument?.map((doc, key) => (
            <tr key={key}>
              <td>
                <p
                  className="font-weight-bold text-dark"
                  style={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {doc?.file_data?.metadata?.filename || doc?.uploadable_type?.match(/[A-Z][a-z]+/g)?.join(' ')}
                </p>
              </td>
              <td>:</td>
              <td colSpan="2">
                {doc.file_url?.includes('pdf') ? (
                  <div className="pdf-wrapper d-flex flex-column justify-content-center align-items-center">
                    <img src="/assets/images/pdf.png" alt="" style={{ height: 90, width: 90, borderRadius: 0 }} />
                    <a
                      href={!doc.file_url.includes('http') ? `https://${doc.file_url}` : doc.file_url}
                      target="_blank"
                      className="btn btn-primary mt-3"
                    >
                      View
                    </a>
                  </div>
                ) : (
                  <img
                    src={!doc.file_url.includes('http') ? `https://${doc.file_url}` : doc.file_url}
                    alt=""
                    style={{ objectFit: 'cover', borderRadius: 0, height: 150, width: 250 }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CompanyDocument
