import { put, takeLatest, call, fork } from '@redux-saga/core/effects'
import { setStatus } from 'services/actions/session'
import {
  getTagSuccess,
  getTagFailed,
  createTagSuccess,
  createTagFailed,
  updateTagSuccess,
  updateTagFailed,
  deleteTagSuccess,
  deleteTagFailed,
} from 'services/actions/tag'
import { TAG } from 'services/constans/tag'
import { getTag, createTag, updateTag, deleteTag } from 'services/apis/tag'

function* getTagSaga() {
  const getTagRequest = yield call(getTag)
  if (getTagRequest.ok) {
    yield put(getTagSuccess(getTagRequest))
  } else {
    yield put(getTagFailed(getTagRequest))
  }
}

function* createTagSaga({ form, callback }) {
  const createTagRequest = yield call(createTag, form)
  if (createTagRequest.ok) {
    yield put(createTagSuccess(createTagRequest))
    yield fork(getTagSaga)
    yield callback()
  } else {
    yield put(createTagFailed(createTagRequest))
  }
}

function* updateTagSaga({ form, id, callback }) {
  const updateTagRequest = yield call(updateTag, id, form)
  if (updateTagRequest.ok) {
    yield put(updateTagSuccess(updateTagRequest))
    yield fork(getTagSaga)
    yield callback()
  } else {
    yield put(updateTagFailed(updateTagRequest))
  }
}

function* deleteTagSaga({ id }) {
  const deleteTagRequest = yield call(deleteTag, id)
  if (deleteTagRequest.ok) {
    yield put(deleteTagSuccess(deleteTagRequest))
    yield fork(getTagSaga)
  } else {
    yield put(deleteTagFailed(deleteTagRequest))
  }
}

function* watchSaga() {
  yield takeLatest(TAG.GET_TAG, getTagSaga)
  yield takeLatest(TAG.CREATE_TAG, createTagSaga)
  yield takeLatest(TAG.UPDATE_TAG, updateTagSaga)
  yield takeLatest(TAG.DELETE_TAG, deleteTagSaga)
}

export default watchSaga
