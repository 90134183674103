import { TAG } from 'services/constans/tag'

// get
export const getTag = () => ({
  type: TAG.GET_TAG,
})

export const getTagSuccess = payload => ({
  type: TAG.GET_TAG_SUCCESS,
  payload,
})

export const getTagFailed = payload => ({
  type: TAG.GET_TAG_FAILED,
  payload,
})

// create
export const createTag = (form, callback) => ({
  type: TAG.CREATE_TAG,
  form,
  callback,
})

export const createTagSuccess = payload => ({
  type: TAG.CREATE_TAG_SUCCESS,
  payload,
})

export const createTagFailed = payload => ({
  type: TAG.CREATE_TAG_FAILED,
  payload,
})

// update
export const updateTag = (id, form, callback) => ({
  type: TAG.UPDATE_TAG,
  form,
  id,
  callback,
})

export const updateTagSuccess = payload => ({
  type: TAG.UPDATE_TAG_SUCCESS,
  payload,
})

export const updateTagFailed = payload => ({
  type: TAG.UPDATE_TAG_FAILED,
  payload,
})

// delete
export const deleteTag = id => ({
  type: TAG.DELETE_TAG,

  id,
})

export const deleteTagSuccess = payload => ({
  type: TAG.DELETE_TAG_SUCCESS,
  payload,
})

export const deleteTagFailed = payload => ({
  type: TAG.DELETE_TAG_FAILED,
  payload,
})
