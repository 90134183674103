import { useState, useEffect, useReducer } from 'react'
import Pagination from 'components/shared/pagination'
import AdminLayout from 'components/layouts/Admin'
import { useSelector, useDispatch } from 'react-redux'
import { getUser } from 'services/actions/user'
import moment from 'moment'

const User = () => {
  const users = useSelector(state => state.user?.data)
  const meta = useSelector(state => state.user?.meta)
  const [currentPage, setCurrentPage] = useState(1)
  const Dispatch = useDispatch()

  useEffect(() => {
    Dispatch(
      getUser({
        page: currentPage,
      })
    )
  }, [currentPage])

  return (
    <AdminLayout>
      <div className="row">
        {/* <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group d-flex mb-0">
                    <label
                      htmlFor="application-filter-type"
                      className="col-form-label text-dark font-weight-bold"
                      style={{ width: 100 }}
                    >
                      Sort By
                    </label>
                    <select name="" id="application-filter-type" className="form-control  ml-3">
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      className="form-control "
                      id="inlineFormInputGroup"
                      placeholder="Search User ..."
                    />
                    <div className="input-group-append">
                      <button className="btn btn-success font-weight-bold">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-12 grid-margin mt-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-wrap justify-content-between">
                <h4 className="card-title">
                  <span className="text-success">{users?.length}</span> Users
                </h4>
              </div>
              <div className="table-responsive">
                <table className="table center-aligned-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <span className="font-weight-bold">Name</span>
                      </th>
                      <th>
                        <span className="font-weight-bold">Contact Number</span>
                      </th>
                      <th>
                        <span className="font-weight-bold">Email</span>
                      </th>
                      <th>
                        <span className="font-weight-bold">Join Date</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log(users)}
                    {users?.map((usr, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td className="pl-0">{usr?.profile?.name}</td>
                        <td>{usr?.profile?.mobile_phone_number}</td>

                        <td>{usr?.email}</td>
                        <td>{moment(usr?.created_at).format('YYYY-MM-DD hh:mm A')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center">
                <Pagination meta={meta} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default User
