import { APPLICATION } from 'services/constans/application'

export const getApplication = params => ({
  type: APPLICATION.GET_APPLICATION,
  params,
})

export const getApplicationSucces = payload => ({
  type: APPLICATION.GET_APPLICATION_SUCCESS,
  payload,
})

export const getApplicationFailed = payload => ({
  type: APPLICATION.GET_APPLICATION_FAILED,
  payload,
})

export const approveApplication = id => ({
  type: APPLICATION.APPROVE_APPLICATION,
  id,
})

export const rejectApplication = id => ({
  type: APPLICATION.REJECT_APPLICATION,
  id,
})


export const scoreCard = id => ({
  type: APPLICATION.SCORE_CARD,
  id,
})