import AdminLayout from 'components/layouts/Admin'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { createTag, updateTag } from 'services/actions/tag'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'

const tagSchema = yup.object().shape({
  name: yup.string().required(),
})

const TagCreate = ({ tab, setTab }) => {
  const Dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(tagSchema),
    defaultValues: {
      name: tab?.attributes?.name || '',
    },
  })

  const onSubmit = data => {
    if (tab.attributes?.id) {
      Dispatch(
        updateTag(tab.attributes?.id, data, () => {
          setTab({ name: 'index' })
        })
      )
    } else {
      Dispatch(
        createTag(data, () => {
          setTab({ name: 'index' })
        })
      )
    }
  }

  return (
    <AdminLayout>
      <div className="pb-5">
        <a href="" className="text-decoration-none text-dark btn-back-action" onClick={() => setTab({ name: 'index' })}>
          <i className="mdi  mdi-chevron-left text-dark"></i>
          <span>Back</span>
        </a>
        <div className="row">
          <div className="col-12 grid-margin mt-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="text-secondary font-weight-normal">
                        {tab.attributes?.id ? 'Update Tag' : 'Add New Tag'}
                      </h3>
                      <div className="form-group">
                        <div className="form-group">
                          <label htmlFor="" className="col-form-label text-dark font-weight-bold">
                            Tag Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter tag name"
                            {...register('name')}
                          />
                          {errors.name && <small className="text-danger">{_.upperFirst(errors.name.message)}</small>}
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <button className="btn btn-outline-success" onClick={() => setTab({ name: 'index' })}>
                          Cancel
                        </button>
                        <button className="btn btn-success ml-3" type="submit">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default TagCreate
