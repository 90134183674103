import { USER } from 'services/constans/user'

export const getUser = () => ({
  type: USER.GET_USER,
})

export const getUserSucces = payload => ({
  type: USER.GET_USER_SUCCES,
  payload,
})

export const getUserFailed = payload => ({
  type: USER.GET_USER_FAILED,
  payload,
})
