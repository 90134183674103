import { webApi } from './config'
//api/v1/user/companies

export const getCompany = params => webApi({ auth: true }).get('/admin/companies', params)

export const getCompanyDetail = id => webApi({ auth: true }).get(`/admin/companies/${id}`)

export const getCompanyStructure = id => webApi({ auth: true }).get(`/admin/companies/${id}/company_structures`)

export const getCompanyFinancial = id => webApi({ auth: true }).get(`/admin/companies/${id}/financial_reports`)

export const getCompanyDocument = id => webApi({ auth: true }).get(`/admin/uploadable/companies/${id}/uploads`)
