export const APPLICATION = {
  GET_APPLICATION: 'GET_APPLICATION',
  GET_APPLICATION_SUCCESS: 'GET_APPLICATION_SUCCESS',
  GET_APPLICATION_FAILED: 'GET_APPLICATION_SUCCESS',

  APPROVE_APPLICATION: 'APPROVE_APPLICATION',
  REJECT_APPLICATION: 'REJECT_APPLICATION',
  SCORE_CARD: 'SCORE_CARD',

}
