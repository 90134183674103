export const TAG = {
  GET_TAG: 'TAG_GET_TAG',
  GET_TAG_SUCCESS: 'TAG_GET_TAG_SUCCESS',
  GET_TAG_FAILED: 'TAG_GET_TAG_FAILED',

  CREATE_TAG: 'TAG_CREATE_TAG',
  CREATE_TAG_SUCCESS: 'TAG_CREATE_TAG_SUCCESS',
  CREATE_TAG_FAILED: 'TAG_CREATE_TAG_FAILED',

  UPDATE_TAG: 'TAG_UPDATE_TAG',
  UPDATE_TAG_SUCCESS: 'TAG_UPDATE_TAG_SUCCESS',
  UPDATE_TAG_FAILED: 'TAG_UPDATE_TAG_FAILED',

  DELETE_TAG: 'TAG_DELETE_TAG',
  DELETE_TAG_SUCCESS: 'TAG_DELETE_TAG_SUCCESS',
  DELETE_TAG_FAILED: 'TAG_DELETE_TAG_FAILED',
}
