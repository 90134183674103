import { USER } from 'services/constans/user'

const initialState = {
  message: null,
  status: null,
  data: [],
  meta: {},
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER.GET_USER:
      return {
        ...state,
        status: 'request',
        data: [],
      }
    case USER.GET_USER_SUCCES:
      return {
        ...state,
        status: 'request',
        data: action?.payload?.data?.data,
        meta: action?.payload?.data?.meta,
      }
    case USER.GET_USER_FAILED:
      return {
        ...state,
        status: 'failed',
        message: action?.payload?.data?.message,
      }
    default:
      return state
  }
}

export default user
