import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from 'services/actions/session'

const Navbar = () => {
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active')
  }
  const Dispatch = useDispatch()

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-between">
        <Link className="navbar-brand brand-logo justify-content-center w-100" to="/admin/applications/">
          <img src="/assets/images/logo.png" alt="logo" style={{ width: 'unset', height: 72 }} />
        </Link>
        <Link className="navbar-brand brand-logo-mini d-inline-block d-lg-none" to="/">
          <img src="/assets/images/logo-mini.svg" alt="logo" />
        </Link>
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle('sidebar-icon-only')}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item nav-profile d-none d-sm-flex">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <div className="nav-profile-img">
                  <img src="/assets/images/faces/face8.jpg" alt="user" />
                </div>
              </Dropdown.Toggle>
            </Dropdown>
          </li> */}
          <li className="nav-item nav-settings d-none d-lg-block">
            <a
              href="?"
              className="btn btn-success text-success font-weight-bold bg-white border-0 px-0 pt-3 shadow-none"
              onClick={e => {
                e.preventDefault()
                Dispatch(logout())
              }}
            >
              Logout
            </a>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  )
}

export default Navbar
