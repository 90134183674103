import { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import AdminLayout from 'components/layouts/Admin'
import { useSelector, useDispatch } from 'react-redux'
import { getCompany } from 'services/actions/company'
import moment from 'moment'
import DetailCompany from './detail'

const DashboardCompany = () => {
  const Dispatch = useDispatch()

  const copanies = useSelector(state => state.company.data)
  const meta = useSelector(state => state.company.meta)
  const [currentPage, setCurrentPage] = useState(1)
  const [tab, setTab] = useState({ name: 'index' })

  useEffect(() => {
    Dispatch(
      getCompany({
        page: currentPage,
      })
    )
  }, [currentPage])

  return (
    <AdminLayout>
      {tab.name === 'index' && (
        <div className="row">
          {/* <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group d-flex mb-0">
                      <label htmlFor="application-filter-type" className="col-form-label text-dark font-weight-bold">
                        Status
                      </label>
                      <select name="" id="application-filter-type" className="form-control form-control-lg ml-3">
                        <option value="">All</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <label className="sr-only" htmlFor="inlineFormInputGroup">
                      Username
                    </label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="inlineFormInputGroup"
                        placeholder="Search Company ..."
                      />
                      <div className="input-group-append">
                        <button className="btn btn-success font-weight-bold">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12 grid-margin mt-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-wrap justify-content-between">
                  <h4 className="card-title">
                    <span className="text-success">{copanies.length}</span> Companies
                  </h4>
                  <div className="ml-auto">
                    <button className="btn btn-outline-success d-flex align-items-center btn-lg px-3">
                      <i className="fa fa-file-excel-o"></i>
                      <span className="ml-2 ">Export CSV</span>
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table center-aligned-table">
                    <thead>
                      <tr>
                        <th>
                          <span className="font-weight-bold">Company</span>
                        </th>
                        <th>
                          <span className="font-weight-bold">Date</span>
                        </th>
                        <th>
                          <span className="font-weight-bold">Director</span>
                        </th>
                        <th>
                          <span className="font-weight-bold">Guarantor</span>
                        </th>
                        <th>
                          <span className="font-weight-bold">User</span>
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {copanies?.map((company, key) => (
                        <tr key={key}>
                          <td className="pl-0">{company.name}</td>
                          <td>{moment(company.created_at).format('YYYY-MM-DD hh:mm A')}</td>
                          <td>
                            <div className="d-flex">
                              <i className="mdi mdi-account"></i>
                              <span className="ml-2">
                                {
                                  company.company_structures.filter(cs => cs.position === 'key_director_particulars')
                                    .length
                                }
                                Person
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <i className="mdi mdi-account"></i>
                              <span className="ml-2">
                                {
                                  company.company_structures.filter(cs => cs.position === 'guarantors_particulars')
                                    .length
                                }{' '}
                                Person
                              </span>
                            </div>
                          </td>
                          <td>
                            <span>{company.company_structures.length}</span>
                          </td>
                          <td>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => setTab({ name: 'detail', detail: company })}
                            >
                              Detail
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {meta?.Total > 0 && (
                  <div className="d-flex justify-content-center">
                    <Pagination className="pagination-success d-flex justify-content-center">
                      <Pagination.Prev
                        disabled={meta.Page <= meta.Total}
                        onClick={() => {
                          if (meta.Page > meta.Total) {
                            setCurrentPage(meta.Page - 1)
                          }
                        }}
                      />
                      {Array.from(new Array(meta?.Total || 0)).map((_, key) => (
                        <Pagination.Item
                          active={currentPage === key + 1}
                          key={key}
                          onClick={() => setCurrentPage(key + 1)}
                        >
                          {key + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        disabled={meta.Page >= meta.Total}
                        onClick={() => {
                          if (meta.Page < meta.Total) {
                            setCurrentPage(meta.Page + 1)
                          }
                        }}
                      />
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {tab.name === 'detail' && <DetailCompany tab={tab} />}
    </AdminLayout>
  )
}

export default DashboardCompany
